import React, { FC } from 'react';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import './FourColumnsRow.scss';

const FourColumnsRow: FC<ArticleTypes.TArticleBody> = ({ columns = [] }) => {
  return columns?.length > 0 ? (
    <div className="four-columns-row">
      {columns.map(({ properties }, idx) => {
        const id = `${properties.content}-${idx}`;

        return properties?.content ? (
          <div key={id} className="four-columns-row__col">
            <DangerouslySetInnerHtml html={properties.content} />
          </div>
        ) : null;
      })}
    </div>
  ) : null;
};

export default FourColumnsRow;
